import React from 'react';
import { Link as RouterLink } from 'gatsby';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Icon from '@material-ui/core/Icon';
import PlaceIcon from '@material-ui/icons/Place';
import PersonIcon from '@material-ui/icons/Person';
import VideocamIcon from '@material-ui/icons/Videocam';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CreateIcon from '@material-ui/icons/Create';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';

import Layout from '../components/Layout';


const backgroundImage = 'https://source.unsplash.com/tzQkuviIuHU';
//const backgroundImage = 'https://source.unsplash.com/NMk1Vggt2hg';

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: 'url(https://source.unsplash.com/tzQkuviIuHU)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
}));

export default function DesignTest () {
    const classes = useStyles();

    return (
      <Layout>
          <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${backgroundImage})` }}>
            {/* Increase the priority of the hero background image */}
            {<img style={{ display: 'none' }} src={backgroundImage} alt={'Welcome to Spookt'} />}
            <div className={classes.overlay} />
            <Grid container>
              <Grid item md={6}>
                <div className={classes.mainFeaturedPostContent}>
                  <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                    Welcome to Spookt
                  </Typography>
                  <Typography variant="h5" color="inherit" paragraph>
                    Discover the spooky and haunted world we live in.
                  </Typography>
                  <Link variant="subtitle1" 
                    component={RouterLink}
                    to={'/about'}>
                    Learn more about our mission
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Paper>
          <Paper>
            I am some text on dp1
          
            <Paper elevation="2" >
                I am some text on dp2
            
                <Paper elevation="4" >
                    I am some text on dp4
                </Paper>
                <Paper elevation="6" >
                    I am some text on dp6
                </Paper>
                <Paper elevation="8" >
                    I am some text on dp8
                </Paper>
                <Paper elevation="12" >
                    I am some text on dp12
                </Paper>
                <Paper elevation="16" >
                    I am some text on dp16
                </Paper>
                <Paper elevation="24" >
                    I am some text on dp24
                </Paper>
                Just some text
                <Paper variant="outlined" >
                    I am some text on outlined
                </Paper>
            </Paper>
          </Paper>
          <Paper>
            <PlaceIcon /> Place
            <CreateIcon /> Lore
            <FormatAlignJustifyIcon /> Lore
            <Icon className="fas fa-scroll" /> Lore
            <i class="fas fa-scroll"></i> Lore
            <PersonIcon /> Person
            <VideocamIcon /> Investigation
            <MenuBookIcon /> Book
          </Paper>
          <Paper>
            <Typography variant="h1">This is an h1</Typography>
            <Typography variant="h2">This is an h2</Typography>
            <Typography variant="h3">This is an h3</Typography>
            <Typography variant="h4">This is an h4</Typography>
            <Typography variant="h5">This is an h5</Typography>
            <Typography variant="subtitle1">This is a subtitle1</Typography>
            <Typography variant="subtitle2">This is a subtitle2</Typography>
            <Typography>This is a body1</Typography>
            <Typography variant="body2">This is a body2</Typography>
            <Typography variant="button">This is a button</Typography>
            <Typography variant="caption">This is a caption</Typography>
            <Typography variant="overline">This is a overline</Typography>
            <Typography color="primary">This is a primary color</Typography>
            <Typography color="secondary">This is a secondary color</Typography>
            <Typography color="textPrimary">This is a textPrimary color</Typography>
            <Typography color="textSecondary">This is a textSecondary color</Typography>
          </Paper>
      </Layout>
    );
  };
